<template>
  <div>
    <hcc-table
      :enableSearch="true"
      :rowsPerPage="10"
      :actualPage="actualPage"
      @pageChanged="changePage"
      @add="create"
      :rows="companiesFeed"
      :columns="columns"
      @edit="edit"
    />
    <hcc-modal
      @confirm="save"
      @opened="focusInput"
      :confirmDisableButton="!enableSave"
      name="add-feed"
      widht="600"
      :title="edit ? $t('company.edit-title') : $t('company.title')"
    >
      <div class="modal-company">
        <hcc-input
          min="0"
          ref="fee"
          type="number"
          v-model.number="fee"
          :label="$t('company.fee')"
          requiredInput
          class="modal-company__item"
        />
        <hcc-input
          type="number"
          min="0"
          class="modal-company__item hint"
          v-model.number="total"
          oninput="this.value = Math.round(this.value)"
          :label="$t('company.total-conversations')"
          requiredInput
        />
        <p class="input-info"> * {{ $t('company.info-conversations', { amount: total + 1 }) }} *</p>
        <hcc-select
          :disabled="isEdit"
          customClass="expanded"
          :label="$t('app.company')"
          v-model="company"
          :options="companies"
          option-label="name"
        />
      </div>
    </hcc-modal>
    <transition name="fade">
      <hcc-confirmation />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
  },
  data() {
    return {
      total: null,
      fee: null,
      company: null,
      isEdit: null,
      columns: [{
        label: this.$t('company.fee'),
        field: 'fee',
      }, {
        label: this.$t('company.total-conversations'),
        field: 'total',
      }, {
        label: this.$t('company.company'),
        field: 'name',
      }],
      actualPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      companies: 'shared/getCompanies',
    }),
    enableSave() {
      return this.total != null && this.company != null && this.fee !== null;
    },
    companiesFeed() {
      return this.companies.flatMap(({ id, name, configuration }) => configuration
        .map(it => ({
          ...it, name, id, fee: `$${it.fee}`,
        })));
    },
  },
  methods: {
    create() {
      this.clear();
      this.$modal.show('add-feed');
    },
    changePage(newPage) {
      this.actualPage = newPage;
    },
    edit(props) {
      this.total = props.total;
      this.fee = parseFloat(props.fee.substring(1));
      this.company = props;
      this.isEdit = true;
      this.$modal.show('add-feed');
    },
    focusInput() {
      this.$refs.fee.focus();
    },
    save() {
      const { configuration = [], ...data } = this.getCompanyFee(this.company.id);
      let setting = configuration.map(({ __typename, ...item }) => item);

      const feedExist = this.alredyExistFee(configuration);
      if (feedExist !== -1) {
        this.$toasted.global.error(this.$t('company.feed-exist'));
        return;
      }

      const props = { ...data };
      if (this.isEdit) {
        setting = this.getRemainder(setting, this.company.fee, this.company.total);
      }

      setting.push({ fee: this.fee, total: this.total });
      props.configuration = setting;

      this.$emit('save-fee', props);
    },
    getCompanyFee(propId) {
      return this.companies.find(({ id }) => id === propId);
    },
    alredyExistFee(configuration) {
      return configuration
        .findIndex(({ total, fee }) => this.total === total && this.fee === fee);
    },
    clear() {
      this.company = null;
      this.total = null;
      this.fee = null;
      this.isEdit = false;
    },
    getRemainder(data, price, size) {
      return data.filter(({ total, fee }) => size !== total
        && price !== fee);
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~styles/views/companies.scss";
</style>
